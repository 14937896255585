import { makeConstant } from '~/helper/typescript';

import type { stringOrNumber } from 'types';

export default makeConstant({
  DISCOVERY_URL: (adminUrl: string) => `${adminUrl}/manage/discovery`,
  DISCOVERY_ID_URL: (adminUrl: string, id: stringOrNumber) => `${adminUrl}/manage/discovery/${id}/update`,
  DISCOVERY_DETAILS_URL: (adminUrl: string, userId: stringOrNumber, firstName: string) =>
    `${adminUrl}/manage/discovery/details?dlref=${userId}&name=${firstName}`,
  DISCOVERY_DETAILS_ALL_URL: (adminUrl: string) => `${adminUrl}/manage/discovery/details`,
  SETTINGS_URL: (adminUrl: string) => `${adminUrl}/manage/settings`,
  TOPICS_ID_ADD_TO_LIBRARY_URL_FUNC: (adminUrl: string, topicId: stringOrNumber) =>
    `${adminUrl}/manage/topics/${topicId}/add-to-library`,
});
